@import './variables.scss';
@font-face {
  font-family: 'alqabas';
  src: url(alqabas.ttf);

}
@font-face {
  font-family: 'alqabas';
  src: url(alqabas2.ttf);
  padding-bottom: 2px;
  font-weight: 500;

}
.App{
  overflow-x:hidden!important;
}
*{
  direction: rtl;
  font-family: 'alqabas'!important;
}
body{
  text-align: right;

}
.fz8{
  font-size: em(8);
}
.fz10{
  font-size: em(10);
}
.fz12{
  font-size: em(12);
}
.fz14{
  font-size: em(14);
}
.fz16{
  font-size: em(16);
}
.fz18{
  font-size: em(18);
}
.fz20{
  font-size: em(20);
}
.fz22{
  font-size: em(22);
}
.fz24{
  font-size: em(24);
}
.fz28{
  font-size: em(28);
}
.fz30{
  font-size: em(30);
}
.fz49{
  font-size: em(49);
}
.fz37{
  font-size: em(37);
}

.fzcbrouwn{
  color: #2A1200
}
.bgcbrouwn{
  background-color: #170801
}
.fzcblack{
  color: #393939
}
.fzcblack:hover{
  color: #393939
}
a{
  color: inherit;
}
a:hover{
  color: inherit;
}
.fzcb{
  color: #000
}
.fzcb:hover{
  color: #000
}
.bgcblack{
  background-color: #393939
}
.bcmuted{
  border-color: #717171!important;
}
.fzcmuted{
  color: #717171
}
.fzcmuted:hover{
  color: #717171
}
.fzcwhite{
  color: #FFF
}
.fzcwhite:hover{
  color: #FFF
}
.fzcbs{
  color: #dee2e6;
}
.border-balck{
 
  border:1px solid #393939!important

}
.bgcwhite{
  background-color: #FFF
}
.bgcmuted{
  background-color: #717171
}
.bordercm{
  border:1px solid $maincolor!important
}
.bordercm2{
  border:2px solid $maincolor!important
}
.fzcm{
  color: $maincolor!important
}
.fzcm:hover{
  color: $maincolor
}
.bgcm{
  background-color: $maincolor
}
.fw300{
  font-weight: 300!important;

}

.fw400{
  font-weight: 400!important;

}

.fw500{
  font-weight: 500!important;

}
.fw600{
  font-weight: 600!important;

}
.hrv {
  height: 100%;
  border: 0.5px solid #E1E1E1;
  padding: 0px;
  margin: 1px;
  display: inline
}
.hrv2 {
  height: 150px;
  border: 0.5px solid #E1E1E1;
  padding: 0px;
  margin: 1.5px;
  display: inline
}
.stylelink{
text-decoration: none;
}
.stylelink:hover{
  text-decoration: none;
  color:none
  }
.navhe{
  height: 70px;
}
.btnw{
  width: 170px;
}
.b_r{
  border-radius: 10px;
}
.underline{
  text-decoration: underline!important;

}
.pointer{
  cursor: pointer
}
.borderdashed{
  border: 0.5px solid #dee2e6;
  border-style: dashed;
  }

.require{
    color: red;
    padding:  0 1px;
    font-size: 12px!important;
}
.fh40{
  height: 40px;
  overflow: hidden;
}
.fh35{
  height: 35px;
  overflow: hidden;
}
.fh56{
  height: 56px;
  overflow: hidden;
}
.fh63{
  height: 63px;
  overflow: hidden;
}
.mh65{
  min-height: 65px;
}
.bgct{
  background-color: #07B5EC;
}
.bgcf{
  background-color: #005DB9;
}
.Cface{
  
filter: invert(36%) sepia(13%) saturate(2287%) hue-rotate(183deg) brightness(89%) contrast(93%)
}
.Cwhitw{

  filter: invert(99%) sepia(5%) saturate(3%) hue-rotate(29deg) brightness(105%) contrast(100%);
}
.Cmuted{
  filter: invert(42%) sepia(0%) saturate(24%) hue-rotate(231deg) brightness(104%) contrast(97%)
}
.lineh9{
  line-height: 9px;
}
.form-control:focus{
  border-color:$maincolor!important
}
input,select,.reduc5{
  border-radius: 5px!important;
}
.center-v{
display: flow-root;
align-self: center;
}
.relative{
  position: relative;
}
@media (max-width: 575.98px) {  
.col-1-5{
  width: 100%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
} }

@media (min-width: 576px) {  
  .col-1-5{
  width: 50%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {  
  .col-1-5{
  width: 33.333%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
} }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .col-1-5{
    width: 25%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
 }
 @media(min-width: 1200px){
  .col-1-5{
    width: 20%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
   .App ::-moz-scrollbar {

    width: 10px;
    height: 10px;
  }
  .App ::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  /* Track */
  .App ::-webkit-scrollbar-track {
    margin:  10px;

    box-shadow: inset 0 0 5px #E1E1E1; 
    border-radius: 10px;
  }
  /* Handle */
  .App ::-webkit-scrollbar-thumb {
    background: #E1E1E1; 
    border-radius: 10px;
  }

  /* Handle on hover */
  .App ::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }

  select{
    background-color: #FBF8F6;
    padding: 5px;
    width: 150px;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
  background-image: url('./Images/arrowselect.svg');
  background-repeat: no-repeat;
  background-position: 5% ;
  background-size: 23px;
  border: 2px solid #B9B9B9;
}




input[type=checkbox] {
width: 20px;
height: 20px;
margin: 2px -30px;
padding: 2px 0 0 0;

}
input[type=checkbox].rela 
{  top: 5px;
position: relative;}

hr{
  border:  1px solid #00000029;
  width: 95%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B9B9B9;
  font-weight: 500;
  
}
input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: block;
  width: 30px;
  height: 30px;
  padding: 3px!important;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 50%!important;
  margin: auto
}


input[type="radio"]:after  {
left: 9px;
top: 5px;
width: 5px;
height: 10px;
border: solid rgb(15, 5, 5);
border-width: 0 2px 2px 0;
}
/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #00B964;
}
.animationtext {
  opacity: 1;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 3s;
}
.LoadingPOPUP{
  position: absolute;
  width: 100%;
  z-index: 1000;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  0%   {opacity: 0;}
  25%  {opacity: 0;}
  75%  {opacity: 0;}
  100% {opacity: 1;}
}

/* Standard syntax */
@keyframes example {
  0%   {opacity: 0;}
  25%  {opacity: 0;}
  75%  {opacity: 0;}
  100% {opacity: 1;}
}

@media (max-width: 576px) { 
  .btnw{
    width: 140px;
  }
}

@media (max-width:768px) { 
  .mq-sm-center{
    text-align: center;
  }
  .mq-sm-mxauto{
   margin: 0 auto;
  }
  .mq-w75{
    width: 75%;
  }

}
::-webkit-input-placeholder { /* Edge */
  color: #aaa!important;
  font-size: 12px;
}

::-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aaa!important;
  font-size: 12px;

  
}

::placeholder {
  color: #aaa!important;
  font-size: 12px;

}
.modal {
  padding: 0 10px;
}

.nowrap{
  white-space: nowrap;
}

