@import '../../variables.scss';


.ChooseCampaign{
  
    
    .typesDonations{
        background-color: #FBF8F6;
        
        .slick-current{
            color: $maincolor;
            border-radius: 5px;
            
        }
        .btnshow span {
            display: inline-flex
        }
        
        .btnshow button{
            background-color: #F1F1F1;
            border: none;
            width: 140px;
        }
        .btnshow .left{
            border-radius: 0 20px 0px 0
        }
        .btnshow .right{
            border-radius:20px  0  0px 0 
        }
        .btnshow .active{
            height: 50px;
            background-color: #00B964;
            color: #fff;

        }
        
        .slider1 .slick-slide img{
            filter: invert(82%) sepia(8%) saturate(0%) hue-rotate(196deg) brightness(91%) contrast(30%);      
          }
        .slider1 .slick-current img{
            filter: invert(48%) sepia(29%) saturate(5005%) hue-rotate(124deg) brightness(99%) contrast(101%);
        }
        
        .Showbycategory .slider2 .slick-current .projectitem  {
            color: $maincolor;
            border-radius: 5px;
            border:1px solid $maincolor!important
        }


        .Showall  .center{
            color: #000;
            border-radius: 5px;
        }
      
        .owl-item:not(.center) > div:hover {
            opacity: .75;
            transition: all 1s;

          }
        .slick-dots  li {
            width: 12px;
            height: 12px;

        }
       
        .slick-dots li button:before {
            font-size: 17px;
        }
        .slick-dots li.slick-active button:before{
            color: $maincolor
        }
        .slick-dots{
            direction: ltr!important;

        }
        
        
        .owl-theme .owl-nav [class*='owl-']:hover{
            background-color: transparent;
        }
        .Showall{
            .slick-slide{
                width: auto!important;
            }
            .Campaign{
                border:  1px solid #F1F1F1;
                border-radius: 5px
            }
            
           .item .active{
                border: 2px solid $maincolor;
                color: $maincolor!important;
            }
          }
    }
    
    .animationsliderhide{
        opacity: 0;
        height: 100px;
        margin: -60px 0 0 0; 
        transition: all 1.5s;
            
    }
    .animationslidershow{
        margin: 0; 
        min-height: 250px;
        transition: all 1.5s;
        position: relative;
    }
    .animationsliderhide2{
        opacity: 0;
        margin: 0; 
        
        transition: all 3s;
            
    }
    .animationslidershow2{
        opacity: 1;
        transition: all 3s;
        position: relative;
    }
    .triangle{
        width: 20px;
        height: 20px;
        border-left: 1px solid  #00000029;
        border-top: 1px solid #00000029;
        -ms-transform: rotate(225deg); /* IE 9 */
        -webkit-transform: rotate(225deg); /* Safari prior 9.0 */
        transform: rotate(225deg); /* Standard syntax */
        position: absolute;
        right: 49%;
        top: -10px;
        background-color: #FBF8F6;
    }
    .projectitem {
        max-width: 190px!important;
        min-width: 190px!important;
        width: 100%;
        height: 190px;
        overflow: hidden;
        border:  1px solid #F1F1F1;
        border-radius: 5px
   }
    

}



.Showbycategory,.slick-track,.slick-list{
    direction: ltr!important;
}

.slick-prev, .slick-next{
    width: 35px!important;
    height: 40px!important;
}
.slick-prev{    left: -45px!important;
}
.slick-next{    right: -45px!important;
}
.ChooseCampaign .typesDonations .slick-current{
    color:#000
}