@import '../../../variables.scss';


.steps .active{
    color: #393939;
}
.steps {
    color: #B9B9B9
}
.steps hr{
    margin: 0  0 0 ;
    border: 1.5px solid $maincolor;
    background: $maincolor;
}
.steps .active .stepnumber{
    text-align: center;
    background-color: #393939;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.steps  .stepnumber{
    text-align: center;
    background-color: #B9B9B9;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@media (max-width:768px) { 

    .dismedia{
        display: none;
    }

 }

