@import '../../../variables.scss';


.CardMyDonation{
    width: 100%;
    .card{
        border: none;
         height: 170px;
        box-shadow: 2px 4px 4px #0000000D!important;

        .card-img-top { height: 135px;
                        object-fit: contain;
        }
        .charityimg{
            height: 40px;
            width: 40px;
            object-fit: contain;
        }
        .cardimgLayout{
            overflow: hidden;
            position: relative;
            height: 135px;
            
        }
        
        
          
       
    }

@media (min-width: 576px) { 
    .card{
        max-width: 208px;
    }
}
} 
@media (max-width: 576px) { 
.card-deck{      
    .card{
        max-width: auto!important;
    }
}
}