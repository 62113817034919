@import '../../variables.scss';


.NGODetails{
    
    .ngoProjects{
        background-color: $bgFB;
        .projectitem{
            width: 190px;
            overflow: hidden;
            border:  1px solid #F1F1F1;
            border-radius: 15px
       }
       .owl-stage-outer{
        direction: ltr;
       }
       .owl-dots{
        direction: ltr!important;

       }
       .owl-theme {
        position: relative;
        .owl-next{
            position: absolute;
            right: -7%;
            top: 20%;
        
        }
        .owl-prev{
            position: absolute;
            left: -7%;
            top: 20%;
        
        }
    }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background-color: $maincolor;
      }
      .owl-theme .owl-dots .owl-dot span {
        width: 11px;
        height: 11px;}

        
    }
    .owl-theme .owl-nav [class*='owl-']:hover{
        background-color: transparent;
    }
    @media (max-width: 768px) {
        
            .owl-next{
                right: -5%!important;
            
            }
            .owl-prev{
                left: -5%!important;
            
            }
        
            
    }
}
