@import '../../variables.scss';


.ContactUs{
    select{
        border-color: #B9B9B9;
        background-color: #fff;
    }
    .form-control{
        border-radius: 0;
    }
    .allCampaigns{
        background-color: $bgFB;
    }
    .w35{
        width: 35px;
    }
    .map{
        // background-image: url('../../Images/map.png');
        // min-height: 332px;
        // background-repeat: no-repeat;
        // background-size: cover;
        width: 100%;
        position: relative;
        z-index: 2;
        // .popup{
        //     position: relative;
        //     right:35%;
        //     top:30%
            
        // }
        @media (min-width: 992px) { 
            .popup{
                position: absolute;
                right:11%;
                top:25%;
                z-index: 555;
            }
    } 
    @media (max-width: 992px) { 
        .popup{
            position: relative;
            z-index: 555;

        }
    }
}
}
