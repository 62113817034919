@import '../../variables.scss';


.ForgotPassword{
    background-color: $bgFB;

    .formlogin{
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #00000029;
        .labelstyle{
            bottom: -10px;
            background-color: #fff;
            right: 45%;
            position: relative;
            display: inline-block;
        }
        input{
            border-radius: 0;
        }
    }


}
