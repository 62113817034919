@import '../../variables.scss';


.MyFavourite{
    select{
        border-color: #B9B9B9;
        background-color: #fff;
    }
    .form-control{
        border-radius: 0;
    }
    .allCampaigns{
        background-color: $bgFB;


       
    }


}
