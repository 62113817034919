@import '../../variables.scss';


.ProjectsListing{
    .searchbarshow2{
        position: absolute;
        top: -9px;
        left: 30px;
        width: 250px;
        padding: 2px 5px;
        opacity: 1;
        transition: opacity   0.2s;
        // background-color: #000;
        border-radius: 5px;
    }
    .searchbar2{
        position: absolute;
        top: -200px;
        left: 0%;
        width: 90%;
        opacity: 0;
        padding: 2px 5px;
        background-color: transparent;
        transition: opacity 0.2s;
    }
    .datashow{
        min-height: 250px;
        opacity: 1;
        height:auto;
        transition: opacity 0.5s;
    }
    .datahide{
        opacity: 0;
        // display: none;
        height: 1px;
        overflow: hidden;

        transition: opacity 0.5s;
    }
    select{
        border-color: #B9B9B9;
        background-color: #fff;
    }
    .form-control{
        border-radius: 0;
    }
    .allProjects{
        background-color: $bgFB;
        .projectitem{
             width: 190px;
             height: 190px;
             overflow: hidden;
             border:  1px solid #F1F1F1;
             border-radius: 5px
        }
        
       
    }
    
.modal-content{    margin-top: 10%;}
.triangle{
    width: 20px;
    height: 20px;
    border-left: 1px solid  #00000029;
    border-top: 1px solid #00000029;
    -ms-transform: rotate(225deg); /* IE 9 */
    -webkit-transform: rotate(225deg); /* Safari prior 9.0 */
    transform: rotate(225deg); /* Standard syntax */
    position: absolute;
    right: 49%;
    bottom: -9px;
    background-color: #FBF8F6;
}

.slider3 .slick-slide img{
    filter: invert(82%) sepia(8%) saturate(0%) hue-rotate(196deg) brightness(91%) contrast(30%);      
  }
.slider3 .slick-current img{
    filter: invert(48%) sepia(29%) saturate(5005%) hue-rotate(124deg) brightness(99%) contrast(101%);
}
.Showbycategory .slider2 .center{
    color: $maincolor;
    border-radius: 5px;
    border:1px solid $maincolor!important
}
.Showall  .center{
    color: #000;
    border-radius: 5px;
}
}


    @media (max-width: 576px) { 
        
        .slick-arrow , .slick-prev{
            display: none!important;
        }
     }

