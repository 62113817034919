@import '../../variables.scss';


.Home{
    header{
        background-image: url('../../Images/cover.png');
        min-height: 332px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        width: 100%;
        background-position-y: center;
    }
    
    .donations{
        background-color:#FBF8F6;
        

    }
    .selecthome{
        width: auto;
         border: none
    }

    .ChooseCampaign{
        .owl-theme .owl-nav [class*='owl-']:hover{
            background-color: transparent;
        }
        .owl-stage-outer{
            direction: ltr!important;

        }
           
        .owl-dots{
            direction: ltr!important;

        }
        .owl-theme .owl-dots .owl-dot span {
            width: 12px;
            height: 12px;

        }
        .owl-theme {
            position: relative;
            .owl-next{
                position: absolute;
                right: -7%;
                top: 15%;
            
            }
            .owl-prev{
                position: absolute;
                left: -7%;
                top: 15%;
            
            }
        }
        .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
            background: #01733E;
        }
    }
    .cooperationWith{
        .owl-theme .owl-nav [class*='owl-']:hover{
            background-color: transparent;
        }
        .owl-stage-outer{
            direction: ltr;
           }
           .owl-dots{
            direction: ltr!important;

           }
        .owl-theme {
            position: relative;
            .owl-next{
                position: absolute;
                right: -7%;
                top: 20%;
            
            }
            .owl-prev{
                position: absolute;
                left: -7%;
                top: 20%;
            
            }
        }
    }
    @media (max-width: 768px) {
        .selecthome{
            width: 100%;
             border: none
        }
            .owl-next{
                right: -5%!important;
            
            }
            .owl-prev{
                left: -5%!important;
            
            }
        
            .titlecover{
                position: absolute;
                top: 33%;
                left: 33%;
                /* transform: translate(-63%,-35%); */
                overflow: hidden;
                
            } 
    }
}
