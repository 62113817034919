@import '../../variables.scss';


.CreateCampaign{
    
    .createDonations{
        background-color: $bgFB;


        form{
                
            color: #717171;
            font-weight: 500;
            
            .borderdashed{
                border: 1px solid #dee2e6;
                border-style: dashed;
            }
           figcaption{
               text-decoration: underline!important;
           }
            
            
            input{
                border-color: #dee2e6;
            }
            .form-control{
                border-radius: 0;
            }
        }
        .labelstyle{
            bottom: -10px;
            background-color: $bgFB;
            position: relative;
            display: inline-block;
        }
    }

.modal{
    margin-top: 9%;
}

}
