@import '../../variables.scss';


.Campaigns{
    select{
        border-color: #B9B9B9;
        background-color: #fff;
    }
    .form-control{
        border-radius: 0;
    }
    .allCampaigns{
        background-color: $bgFB;


       
    }
    .searchbarshow2{
        position: absolute;
        top: -9px;
        left: 30px;
        width: 250px;
        padding: 2px 5px;
        opacity: 1;
        transition: opacity   0.2s;
        // background-color: #000;
        border-radius: 5px;
    }
    .searchbar2{
        position: absolute;
        top: -100px;
        left: 0%;
        width: 90%;
        opacity: 0;
        padding: 2px 5px;
        background-color: transparent;
        transition: opacity 0.2s;
    }
    @media (max-width: 768px) {
        .card {
            margin: 10px auto;
        }
        // .searchbar2{
        //     position: absolute;
        //     top: -100px;
        //     left: 0%;
        //     width: 90%;
        //     opacity: 0;
        //     padding: 2px 5px;
        //     background-color: transparent;
        //     transition: opacity 0.2s;
        // }
    }

}
