@import "../../../variables.scss";

.colornav {
  background-color: #000;
  height: 60px;
  transition: all 0.5s;
}
.colornav2 {
  background-color: rgba(0, 0, 0, 0.5);
  height: 70px;
  transition: all 0.5s;
}
.Navbar {
  .searchbarshow {
    position: absolute;
    top: -9px;
    left: 0%;
    width: 95%;
    padding: 2px 5px;
    opacity: 1;
    transition: opacity 0.2s;
    background-color: #000;
    border-radius: 5px;
    z-index: 999;
  }
  .searchbar {
    position: absolute;
    top: -100px;
    left: 0%;
    width: 90%;
    opacity: 0;
    padding: 2px 5px;
    background-color: transparent;
    transition: opacity 0.2s;
    z-index: 999;
  }
  a {
    text-decoration: none;
  }
  .logoWhite {
    width: 170px;
  }
  .searchicon {
    width: 16px;
  }
  .dropdown-menu {
    top: 8px !important;
    left: -28px !important;
    width: 230px;
    position: relative;
  }
  .searchdiv .dropdown-menu {
    top: 1px !important;
    right: 33px !important;
    width: 88%;
    max-height: 300px;
    overflow: auto;
    position: absolute;
    overflow-x: hidden;

  }
  .triangle {
    width: 15px;
    height: 15px;
    position: absolute;
    background-color: #fff;
    left: 30px;
    z-index: -1;
    top: -5px;
    -ms-transform: rotate(225deg); /* IE 9 */
    -webkit-transform: rotate(225deg); /* Safari prior 9.0 */
    transform: rotate(225deg); /* Standard syntax */
  }
  .dropdownsearch {
    height: 200px;

    background-color: #fff;
    z-index: 1;
  }
  .faildsearch:hover {
    background-color: #000 !important;
  }
}
.colornav2 .dropdown-menu {
  top: 14px !important;
}

.contantwithSM{
  position: relative;
  right: 60%;
  transition: right 1s;
}
.contant{
  position: relative;
  right:  0%;
  transition: right 1s;

}
.side{
  position: fixed;
  right: 0%;
  top: 60;
  width: 60%;
  height: 100%;
  background-color: #000;
}
.side a:hover{
  background-color: #555;

}
.ch{
  background-color: #555;

}
.sidemenunone{
  right: -60%;
  transition: right 1s;
  top: 0;

}
.sidemenushow{
  right: 0%;
  transition: right 1s;
  top: 0;

}

.sidemenusearch{
  border: 1px solid #555;
  max-height: 200px;
  overflow: auto;
}
.searchitemRes{
  border : 1px solid #393e46;
  
}
.bgcb2{
  background-color : #393e46;

}
.searchitemRes:hover{
  background-color : #393e46;
}