@import '../../variables.scss';


.HowItWork{
    
    header{
        background-image: url('../../Images/shutterstock2.png');
        min-height: 250px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        width: 100%;
    }
     .stepnumber{
        text-align: center;
        background-color: #fff;
        border: 2px solid $maincolor;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}
