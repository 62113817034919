@import '../../variables.scss';


.FAQs{
    
    .singlefaq{
        background-color: #FBF8F6;
        border-radius: 5px;
        
    }
[aria-expanded=false]{
    background-color: #fff!important

}
.collapsed{
    // background-image: url('../../Images/icon1.svg');
    background-repeat: no-repeat;
    background-position: 5% ;
    background-size: 23px;
}
[aria-expanded=false] .row .img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    background-image: url('../../Images/Icon1.svg');
    background-position:0 120% ;
    background-size: 23px;
    background-repeat: no-repeat;

}
[aria-expanded=true] .row .img {
    height: 20px;
    width: 20px;
    margin-top: 4px;
    background-image: url('../../Images/Icon2.svg');
    background-position:0 120% ;
    background-size: 23px;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    transition: transform 0.5s;
}
}
