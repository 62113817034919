@import '../../variables.scss';


.Impact{
    // .slider1 .item{
    //     border-right: 1px solid ;
    // }
    .slider1 .item::after{
        content: " ";
        height: 65px;
        width: 0px;
        position: absolute;
        border: 1px solid #00000029;
        top: 7px;
        right: -15px;
    }
    .owl-item:not(.center) > div:hover{
        opacity: 1!important;
    }
    .owl-item:not(.center) > div:hover {
        opacity: .75;
        transition: all 1s;

      }
    .owl-theme .owl-dots .owl-dot span {
        width: 12px;
        height: 12px;

    }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
        background: #01733E;
    }
    .owl-nav img {
        width: 35px;
    }
    .owl-stage-outer{
        direction: ltr!important;

    }
    .owl-dots{
        direction: ltr!important;

    }
    .owl-theme .owl-nav [class*='owl-']:hover{
        background-color: transparent;
    }
    .owl-theme {
        position: relative;
        .owl-next{
            position: absolute;
            right: -7%;
            top: 25%;
        
        }
        .owl-prev{
            position: absolute;
            left: -7%;
            top: 25%;
        
        }
    }
    .triangle{
        width: 20px;
        height: 20px;
        border-left: 1px solid  #00000029;
        border-top: 1px solid #00000029;
        -ms-transform: rotate(225deg); /* IE 9 */
        -webkit-transform: rotate(225deg); /* Safari prior 9.0 */
        transform: rotate(225deg); /* Standard syntax */
        position: absolute;
        right: 49%;
        top: -10px;
        background-color: #fff;
    }
}
