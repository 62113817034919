@import '../../variables.scss';


.EditProfile{
    
    form{
        color: #717171;
        font-weight: 500;

        input,select{
            border-color: #B9B9B9;
        }
        .form-control{
            border-radius: 0;
        }
    }


}
