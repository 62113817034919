@import '../../../variables.scss';


.CardDonation{
    
    .card{
        min-width: 208px;
        margin: 10px;
        border: none;
        height: 300px;
        .dead{
            width: 100%;
            height: 10px;
            position: absolute;
            top: 10px;
            left: -55px;
            rotate: deg(50);
            transform: rotate(-45deg);
            background-color: #333;

        }
        box-shadow: 2px 4px 4px #0000000D!important;
        .card-img-top { height: 135px;
                        object-fit: contain;
                        // temporarily
                        // object-position: bottom;
        }
        .charityimg{
            height: 50px;
            width: 50px;
            object-fit: contain;
        }
        .cardimgLayout{
            overflow: hidden;
            position: relative;
            height: 135px;
            img{
                border: 4px solid #fff;

            }
        }
        
        
        .cardimgLayout:hover .imgLayout{
            background: rgba(255,255,255, 0.4);
            width: 100%;
            height: 100%;
            position: absolute;
            top:0%;
            
        }
        .cardimgLayout .favorite{
            position: absolute;
            left: -150%;
            background-color: #fff;
            border-radius: 5px;
            z-index: 100;
            transition: left 1s;
        }
        .cardimgLayout:hover .favorite {
            left: 0%;

        }
        .progress{
            border-radius: 25px;
            height: 6px;
            background-color:#E1E1E1;

            .progress-bar{
                    background-color: #00B964;
            }
        }
        
    }
    .modal-dialog{
        margin-top: 10%;
    }
@media (min-width: 576px) { 
        .card{
            max-width: 208px;
        }
        
    }
} 
@media (max-width: 576px) { 
.card-deck{      
        .card{
            max-width: auto!important;
        }
        
    }
}
@media (max-width: 768px) { 
    .card-deck{      
           
            .dead{
                
                left: -38%!important;
                
    
            }
        }
    }