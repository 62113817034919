@import "../../variables.scss";

.NGOsListing {
  select {
    border-color: #b9b9b9;
    background-color: #fff;
  }
  .form-control {
    border-radius: 0;
  }
  .allNGOs {
    // background-color: $bgFB;
    .ngoitem {
      width: 200px;
      height: 240px;
      overflow: hidden;
      img {
        width: 180px;
        height: 180px;
        object-fit: contain;
        border: 1px solid #f1f1f1;
        box-shadow: 0px 3px 6px #00000029;
      }
    }
  }
  .searchbarshow2 {
    position: absolute;
    top: -9px;
    left: 30px;
    width: 250px;
    padding: 2px 5px;
    opacity: 1;
    transition: opacity 0.2s;
    // background-color: #000;
    border-radius: 5px;
  }
  .searchbar2 {
    position: absolute;
    top: -200px;
    left: 0%;
    width: 90%;
    opacity: 0;
    padding: 2px 5px;
    background-color: transparent;
    transition: opacity 0.2s;
  }
}
